import React, { forwardRef, MutableRefObject, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import axios from 'axios'

import Classnames from 'classnames'
import TestImg from '../../images/revampImg/test1.png'
import Calendar from '../../images/revampImg/calendar.png'
import NextArrowRed from '../../images/revampImg/nextArrowRed.png'
import ChatText from '../../images/revampImg/chatText.png'
import NoImg from '../../images/revampImg/noImg.png'
import moment from 'moment'

//@ts-ignore
import * as Styles from './BlogPage.module.scss'
import { useResizeDetector } from 'react-resize-detector'
import { Link, navigate } from 'gatsby'
import NewNavBar from './NewNavBar'

import ContactUsBtn from './ContactUsBtn'
import { useTranslation } from 'react-i18next'

import Footer from './Footer'
import Dotdotdot from 'react-dotdotdot'

const BlogPage = () => {
  const [selectedTag, setSelectedTag] = useState('all')
  const breakpoints = useBreakpoint()
  const [articleList, setArticleList] = useState<any>([])
  const [hadNextPage, setHadNextPage] = useState(false)

  const [limit, setLimit] = useState(15)
  const { t } = useTranslation()

  const { width, ref } = useResizeDetector()

  const backToTop = () => {
    window?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const getBlogList = async () => {
    const apiEndPoint = `https://app.motex.io/api/blog/getList`
    try {
      const res = await axios.post(`${apiEndPoint}`, {
        
          filter: {
            type: selectedTag == 'all' ? undefined : selectedTag,
          },
          limit: limit,
          sort: 'latest',
     
      })

      return res.data
    } catch (error) {
      console.log(`error`)
      console.log(error)
    }
  }

  const tagList = [
    { key: 'all', title: '全部' },
    { key: 'drawing', title: '圖則管理' },
    { key: 'mission', title: '任務指派' },
    { key: 'installation', title: '安裝管理' },
    { key: 'porperty', title: '資產管理' },
    { key: 'logistic', title: '物流追蹤' },
    { key: 'pinned', title: '焦點文章' },
    { key: 'others', title: '其他' },
  ]

  const getList = async () => {
    const blogData = await getBlogList()
    const displayBlogData = blogData?.blogs?.map(p => {
      return {
        id: p.id,
        title: p.title ?? '-',
        desc: p.description ?? '-',
        content:p.content ?? '-', // below content for testing
    //     content:`<h2 id="ee0728a6a922014e9186aff8f1d9e1368">
    //     Handheld emperor
    // </h2>
    // <p>
    //     Nintendo, a Japanese electronics company, started as a <a href="https://en.wikipedia.org/wiki/Hanafuda"><i>hanafuda</i> cards</a> manufacturer in 1889. In the mid-1970s, they entered the early video games market and became famous for their home video and handheld game consoles. Nintendo introduced consoles like <strong>NES</strong>, <strong>SNES</strong>, and <strong>Wii</strong>. But the most revolutionary was for sure the <strong>Game Boy</strong>.
    // </p>
    // <h2 id="e29d00bf3bfa8dcc4901986ff0f919abc">
    //     A countdown of Nintendo handhelds
    // </h2>
    // <figure class="image image-style-side image_resized" style="width:17.33%;">
    //     <img src="https://ckeditor.com/docs/ckeditor5/latest/assets/img/game_boy.jpg">
    //     <figcaption>
    //         The Nintendo "flagship" - handheld Game Boy
    //     </figcaption>
    // </figure>
    // <ol style="list-style-type:decimal;">
    //     <li>
    //         <span style="color:hsl(0,75%,60%);"><strong>Game &amp; Watch</strong></span> was Nintendo's first product offering out-of-home gaming. From 1980 to 1991, over a hundred games were released, gaining great popularity.
    //     </li>
    //     <li>
    //         In 1989, &nbsp;the original <span style="color:hsl(0,75%,60%);"><strong>Game Boy</strong></span> was released. The new amazing machine utilized a cartridge system, allowing the users to play a multitude of games of all kinds. This was <mark class="marker-yellow">a historical game-changer</mark>. &nbsp;
    //     </li>
    //     <li>
    //         In 2004, Nintendo introduced a new console family called the <span style="color:hsl(0,75%,60%);"><strong>Nintendo DS</strong></span>. It sported a dual LCD screen in a folded shell, with the lower one being a touchscreen.
    //     </li>
    //     <li>
    //         2017 brought the hybrid experience for both couch-preferring gamers and handheld enthusiasts with the release of the <span style="color:hsl(0,75%,60%);"><strong>Nintendo Switch</strong></span>. It offers both a TV mode with high-definition graphics and a handheld mode using the built-in 6.2” display.&nbsp;
    //     </li>
    // </ol>
    // <h2 id="e557a1c4026127c75b492086f567945b1">
    //     Handheld consoles' popularity
    // </h2>
    // <p>
    //     While the most recent Switch is a prevalent choice nowadays, the 2DS and 3DS consoles are still popular. The king, however, is none other than the original wonder — the Game Boy.
    // </p>
    // <figure class="table">
    //     <table>
    //         <tbody>
    //             <tr>
    //                 <td style="background-color:hsl(0, 0%, 60%);">
    //                     <span style="color:hsl(60,75%,60%);">Console</span>
    //                 </td>
    //                 <td style="background-color:hsl(0, 0%, 60%);">
    //                     <span style="color:hsl(60,75%,60%);">Production dates</span>
    //                 </td>
    //                 <td style="background-color:hsl(0, 0%, 60%);">
    //                     <span style="color:hsl(60,75%,60%);">Pieces sold (2021)</span>
    //                 </td>
    //             </tr>
    //             <tr>
    //                 <td style="background-color:hsl(0, 0%, 90%);">
    //                     Game &amp; Watch
    //                 </td>
    //                 <td style="background-color:hsl(0, 0%, 90%);">
    //                     1980-1991, 2020-2021
    //                 </td>
    //                 <td style="background-color:hsl(0, 0%, 90%);">
    //                     44 million
    //                 </td>
    //             </tr>
    //             <tr>
    //                 <td>
    //                     Game Boy
    //                 </td>
    //                 <td>
    //                     1989-2010
    //                 </td>
    //                 <td>
    //                     <span style="color:hsl(0,75%,60%);">201 million</span> <sup>1</sup>
    //                 </td>
    //             </tr>
    //             <tr>
    //                 <td style="background-color:hsl(0, 0%, 90%);">
    //                     Nintendo DS
    //                 </td>
    //                 <td style="background-color:hsl(0, 0%, 90%);">
    //                     2011-2020
    //                 </td>
    //                 <td style="background-color:hsl(0, 0%, 90%);">
    //                     76 million <sup>2</sup>
    //                 </td>
    //             </tr>
    //             <tr>
    //                 <td>
    //                     Switch
    //                 </td>
    //                 <td>
    //                     since 2017
    //                 </td>
    //                 <td>
    //                     93 million
    //                 </td>
    //             </tr>
    //             <tr>
    //                 <td style="background-color:hsl(0, 0%, 90%);" colspan="3">
    //                     <p>
    //                         <span style="font-size:10px;"><sup>1 </sup>119 million Game Boy and Game Boy Color variants, 82 million Game Boy Advance variants.</span>
    //                     </p>
    //                     <p>
    //                         <span style="font-size:10px;"><sup>2</sup> Including all versions: DS, DSi, 2DS, 3DS, and New 2DS/3DS variants.</span>
    //                     </p>
    //                 </td>
    //             </tr>
    //         </tbody>
    //     </table>
    // </figure>
    // <h2 id="e4545ef7c75714a77232a221acc217b8b">
    //     Handheld gaming experience
    // </h2>
    // <blockquote>
    //     <p style="text-align:right;">
    //         <span style="color:hsl(210,75%,60%);">It's dangerous to go alone! Take this.</span>&nbsp;
    //         <br>
    //         <span style="color:hsl(210,75%,60%);">“Legend of Zelda”, 1986</span>
    //     </p>
    // </blockquote>
    // <p>
    //     Games offered by Nintendo include multiple genres, out of which the famous platformer arcade <i>Super Mario</i> <img class="image_resized" style="width:2.3%;" src="https://ckeditor.com/docs/ckeditor5/latest/assets/img/mario.png"> and the adventure roleplay <i>Legend of Zelda</i> <img class="image_resized" style="width:2.3%;" src="https://ckeditor.com/docs/ckeditor5/latest/assets/img/link.png"> series are probably the most iconic.
    // </p>
    // <hr>
    // <p>
    //     Games that can be played on the handheld family include (examples of games listed):
    // </p>
    // <ul>
    //     <li>
    //         Action &amp; adventure games
    //         <ul>
    //             <li>
    //                 The <i>Legend of Zelda</i> series
    //             </li>
    //             <li>
    //                 <i>Chrono Trigger</i>
    //             </li>
    //         </ul>
    //     </li>
    //     <li>
    //         First-person action games
    //         <ul>
    //             <li>
    //                 <i>Splatoon</i>
    //             </li>
    //         </ul>
    //     </li>
    //     <li>
    //         Role-playing games (RPG)
    //         <ul>
    //             <li>
    //                 The <i>Pokemon</i> series
    //             </li>
    //             <li>
    //                 The <i>Final Fantasy</i> series
    //             </li>
    //         </ul>
    //     </li>
    // </ul>`,
        tag: p.type,
        date: moment(p.createdAt).format('DD-MM-YYYY'),
        thumbnail: p.coverImage ?? NoImg,
      }
    })
    setHadNextPage(blogData?.hasNextPage)
    setArticleList(displayBlogData)
  }

  useEffect(() => {
    getList()

    return () => {}
  }, [limit, selectedTag])

  return (
    <>
      <div id="mainTop"></div>
      <NewNavBar notTransparent={true} />

      <main ref={ref} className={Styles.main}>
        <>
          <div className={Styles.blogTitle}>{t('TXT_Web_blog')}</div>
          {/* <div className={Styles.title}>Coming soon</div> */}
          <div className={Styles.blogDesc}>
            {t('TXT_Blog_desc1')}
            <br></br>
            {t('TXT_Blog_desc2')}
          </div>

          <div className={Styles.tagContainer}>
            {tagList.map((t, i) => {
              return (
                <div
                  className={Classnames(Styles.tag, {
                    [Styles.selected]: selectedTag == t.key,
                  })}
                  key={i}
                  onClick={() => setSelectedTag(t.key)}
                >
                  {t.title}
                </div>
              )
            })}
          </div>

          {(articleList && articleList.length > 0 && (
            <>
              <div className={Styles.gridContainer}>
                {articleList?.map((acticle, i) => {
                  return <BlogCard articleData={acticle} key={i} />
                })}
              </div>
              {hadNextPage && (
                <button className={Styles.loadmoreBtn} onClick={() => setLimit(limit + 15)}>
                  {t('TXT_Load_more')}
                </button>
              )}
            </>
          )) || (
            <div className={Styles.noResultContainer}>
              <p style={{ textAlign: 'center' }}>{t('TXT_No_Results')}</p>
            </div>
          )}
        </>

        <ContactUsBtn />
      </main>
      <Footer scrollToTop={() => backToTop()} />
    </>
  )
}

const BlogCard = ({ articleData }: any) => {
  const breakpoints = useBreakpoint()
  const { t } = useTranslation()
  return (
    <div className={Styles.card}>
      <div className={Styles.thumbnail}>
        <img src={articleData.thumbnail} style={{ objectFit: 'contain', width: '100%', height: '100%' }}></img>
      </div>

      <div className={Styles.wrapper}>
        <div className={Styles.tagAndDateRow}>
          <div className={Styles.tag}>{t(`TXT_Tag_${articleData.tag}`) ?? '-'}</div>
          <div className={Styles.date}>
            <img src={Calendar} alt="calendar"></img>
            {articleData.date ?? '-'}
          </div>
        </div>
        <div className={Styles.title}>
          <Dotdotdot clamp={5}>
            <p> {articleData.title}</p>
          </Dotdotdot>
        </div>

        <div className={Styles.desc}>
          <Dotdotdot clamp={5}>
            <p dangerouslySetInnerHTML={{ __html: articleData?.desc ?? '-' }} />
            {/* <p> {articleData.desc}</p> */}
          </Dotdotdot>
        </div>
        <Link
          className={Styles.knowMoreBtn}
          to={`/blog/${articleData.title}`}
          state={{ myProp: articleData }}

          // onClick={() => expand()}
        >
          {t('TXT_Banner_know_more')}

          <img src={NextArrowRed} alt="know more" />
        </Link>
      </div>
    </div>
  )
}

export default BlogPage
