import React from "react"
import BlogPage from "src/components/newHomePage/BlogPage" // old version
import "../../i18n.js"


const BlogMain = () => {
 
    return (
        <BlogPage />
    )
}

export default BlogMain
