// extracted by mini-css-extract-plugin
export var main = "BlogPage-module--main--2iHUS";
export var blogTitle = "BlogPage-module--blogTitle--3HnQY";
export var blogDesc = "BlogPage-module--blogDesc--xsCOS";
export var tagContainer = "BlogPage-module--tagContainer--Gjv8S";
export var tag = "BlogPage-module--tag--28Eyn";
export var selected = "BlogPage-module--selected--2VXNR";
export var gridContainer = "BlogPage-module--gridContainer--3VK7Z";
export var card = "BlogPage-module--card--mKt_C";
export var thumbnail = "BlogPage-module--thumbnail--gulN7";
export var wrapper = "BlogPage-module--wrapper--313dU";
export var tagAndDateRow = "BlogPage-module--tagAndDateRow--1oHq3";
export var date = "BlogPage-module--date--2Lt9X";
export var title = "BlogPage-module--title--WLHsO";
export var desc = "BlogPage-module--desc--2x7J9";
export var knowMoreBtn = "BlogPage-module--knowMoreBtn--3FujZ";
export var noResultContainer = "BlogPage-module--noResultContainer--MJXyz";
export var modalContainer = "BlogPage-module--modalContainer--2phe8";
export var loadmoreBtn = "BlogPage-module--loadmoreBtn--270qv";